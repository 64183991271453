import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { ParallaxLayer } from 'react-spring/renderprops-addons.cjs'

export const ParallaxLayerDivider = styled(ParallaxLayer)`
  ${tw`absolute w-full h-full`};
  svg {
    fill: ${props => props.fill};
  }
`

function ClipPath({ clipPath = 'url(#leftClipPath)' }) {
  return (
    <svg preserveAspectRatio='none' style={{ width: '100%', height: '100%' }} viewBox='0 0 100 100'>
      <rect preserveAspectRatio='none' width='100%' height='100%' clipPath={clipPath} />
    </svg>
  )
}

ClipPath.propTypes = {
  clipPath: PropTypes.oneOf(['url(#leftClipPath)', 'url(#rightClipPath)']),
}
ClipPath.defaultProps = {
  clipPath: 'url(#leftClipPath)',
}

export function Divider(props) {
  const { fill, clipPath, children, ...parallaxProps } = props

  return (
    <ParallaxLayerDivider fill={fill} {...parallaxProps}>
      {clipPath && <ClipPath clipPath={clipPath} />}
      {children}
    </ParallaxLayerDivider>
  )
}

Divider.propTypes = {
  fill: PropTypes.string,
  clipPath: PropTypes.oneOf(['url(#leftClipPath)', 'url(#rightClipPath)']),
  children: PropTypes.node,
}
Divider.defaultProps = {
  fill: '#fff',
  clipPath: null,
  children: undefined,
}
