import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

export const StyledStatusLabel = styled.span`
  ${tw`absolute lg:p-8 p-4 font-sans text-base md:text-xl text-bold w-full text-center`}
  transition: opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${props => props.status === 'active' ? 'rgba(56, 193, 114, 0.9)' : 'rgba(224, 118, 40, 0.85)'};
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 3;
`

export default function StatusLabel({ status }) {
    return (
    <StyledStatusLabel status={status}>
        {status === 'active' && "Active Project"}
        {status === 'inactive' && "Inactive Project"}
    </StyledStatusLabel>
    )
}