import React from 'react'
import PropTypes from 'prop-types'

export default function Mobile({ className, image }) {
  return (
    <svg
      className={className}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='294.012px'
      height='598.012px'
      viewBox='0 0 294.012 598.012'
      enableBackground='new 0 0 294.012 598.012'
      xmlSpace='preserve'
    >
      <g>
        <image
          overflow='visible'
          width='1081'
          height='1920'
          xlinkHref={image}
          transform='matrix(0.2486 0 0 0.2486 12.0061 60.6782)'
          style={{ outline: '2px solid #1b1f22' }}
        />
        <path
          fill='#E6E9ED'
          strokeWidth='1px'
          stroke='#CCCCCC'
          d='M294.008,299.001c0,86.656,0.014,173.312-0.011,259.964c-0.006,20.002-12.287,34.996-31.53,38.51
      		c-2.759,0.504-5.634,0.518-8.455,0.52c-71.49,0.025-142.979,0.031-214.472,0.014c-23.535-0.004-39.522-15.959-39.525-39.527
      		c-0.02-172.977-0.02-345.953,0-518.93c0.003-23.564,15.963-39.52,39.517-39.53c71.655-0.028,143.313-0.029,214.97,0
      		c23.564,0.01,39.494,15.96,39.499,39.516C294.019,126.025,294.008,212.514,294.008,299.001z M280.211,299.513
      		c0-77.833-0.039-155.666,0.105-233.499c0.009-4.245-1.129-5.103-5.204-5.097c-85.5,0.128-171,0.144-256.499-0.027
      		c-4.811-0.01-5.545,1.512-5.542,5.816c0.104,155,0.112,310-0.027,465c-0.004,4.846,1.193,6.004,6.002,5.996
      		c85.167-0.156,170.333-0.15,255.499-0.014c4.489,0.008,5.787-1.012,5.778-5.676C280.166,454.512,280.211,377.013,280.211,299.513z
      		 M147.246,30.015c15.327,0,30.654-0.051,45.982,0.035c3.876,0.022,7.582-0.715,7.54-5.15c-0.039-4.053-3.528-4.935-7.27-4.927
      		c-30.656,0.067-61.313,0.067-91.969-0.001c-3.736-0.008-7.232,0.861-7.288,4.908c-0.063,4.436,3.638,5.191,7.519,5.17
      		C116.923,29.964,132.084,30.015,147.246,30.015z M147.777,570.01c-15.327,0-30.655,0.053-45.983-0.035
      		c-3.874-0.021-7.583,0.705-7.551,5.141c0.028,4.057,3.518,4.945,7.259,4.938c30.656-0.064,61.312-0.066,91.968,0.002
      		c3.735,0.008,7.235-0.854,7.3-4.898c0.07-4.436-3.625-5.201-7.508-5.18C178.1,570.061,162.938,570.01,147.777,570.01z
      		 M57.206,20.022c-3.021,0.267-5.001,1.804-5.096,4.902c-0.085,2.851,1.644,4.723,4.44,4.966c3.078,0.267,5.103-1.506,5.335-4.546
      		C62.123,22.254,60.214,20.558,57.206,20.022z'
        />
        <path
          fill='#CCCCCC'
          d='M147.246,30.015c-15.162,0-30.323-0.051-45.484,0.035c-3.881,0.021-7.58-0.734-7.519-5.17
      		c0.056-4.047,3.552-4.916,7.289-4.908c30.655,0.068,61.312,0.068,91.968,0.001c3.74-0.008,7.229,0.874,7.27,4.927
      		c0.042,4.436-3.664,5.173-7.54,5.15C177.901,29.964,162.573,30.015,147.246,30.015z'
        />
        <path
          fill='#CCCCCC'
          d='M147.777,570.01c15.161,0,30.322,0.051,45.483-0.035c3.883-0.02,7.578,0.746,7.508,5.182
      		c-0.063,4.043-3.563,4.906-7.3,4.896c-30.656-0.066-61.313-0.066-91.968,0c-3.741,0.008-7.229-0.881-7.259-4.938
      		c-0.032-4.436,3.677-5.162,7.551-5.143C117.121,570.063,132.449,570.01,147.777,570.01z'
        />
        <path
          fill='#CCCCCC'
          d='M57.206,20.022c3.008,0.535,4.917,2.231,4.681,5.322c-0.232,3.04-2.257,4.813-5.335,4.546
      		c-2.798-0.243-4.525-2.115-4.44-4.966C52.205,21.826,54.185,20.289,57.206,20.022z'
        />
      </g>
    </svg>
  )
}
Mobile.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
}

Mobile.defaultProps = {
  image: '',
  className: '',
}
