import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from '../elements/dividers'
import Content from '../elements/content'
import Inner from '../elements/inner'

const About = ({ children, offset }) => (
  <>
    <Divider fill='rgba(35, 38, 43, 0.75)' clipPath='url(#rightClipPath)' speed={0.2} offset={offset} />
    <Content speed={0.4} offset={offset}>
      <Inner>{children}</Inner>
    </Content>
  </>
)

export default About

About.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
}
