import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import StatusLabel, { StyledStatusLabel } from './StatusLabel'

const TextWrapper = styled.div`
  ${tw`absolute px-8 py-8 flex flex-col justify-end w-full`}
  transition: opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  z-index: 3;
`

const Overlay = styled.div`
  ${tw`absolute`}
  z-index: 2;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, all 450ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Wrapper = styled.a`
  ${tw`relative no-underline overflow-hidden rounded-lg text-white my-4 w-full`};

  &:hover ${TextWrapper} {
    opacity: 1;
  }

  &:hover ${StyledStatusLabel} {
    opacity: 1;
  }

  &:hover ${Overlay} {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.94);
  }
`

const InnerWrapper = styled.div`
  height: 0;
  padding-top: ${(1080 / 1920) * 100}%;
  background: ${props => props.bg};
  background-size: contain;
  position: relative;
  z-index: -1;
`

const Circle = styled.div`
  background: ${props => props.background};
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin: 6px 0px 6px 6px;
  margin-left: ${props => props.marginLeft};
`

const Text = styled.div`
  ${tw`opacity-75 font-sans text-sm md:text-base`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`

const Title = styled.div`
  ${tw`text-white uppercase text-2xl md:text-3xl xl:text-4xl tracking-wide font-sans`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`

const ProjectWebsite = ({ title, link, children, bg, status }) => (
  <Wrapper href={link} target='_blank' rel='noopener noreferrer'>
    <Overlay>
      <StatusLabel status={status} />
      <TextWrapper>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </TextWrapper>
    </Overlay>
    <div style={{ height: '32px', backgroundColor: '#e6e9ed', zIndex: -1 }}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ height: '32px', display: 'flex', alignItems: 'center', justifyCenter: 'center' }}>
          <Circle background='#FF483F' marginLeft='8px' />
          <Circle background='#ECBB38' />
          <Circle background='#36D3A8' />
        </div>
      </div>
    </div>
    <InnerWrapper bg={bg} />
  </Wrapper>
)

export default ProjectWebsite

ProjectWebsite.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bg: PropTypes.string.isRequired,
  status: PropTypes.oneOf('inactive', 'active').isRequired
}
