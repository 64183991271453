import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import Mobile from '../elements/mobile'

import StatusLabel, { StyledStatusLabel } from './StatusLabel'


const TextWrapper = styled.div`
  ${tw`absolute px-8 py-8 flex flex-col justify-end`}
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Overlay = styled.div`
  ${tw`absolute`}
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-radius: 12%/6%;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, all 450ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Wrapper = styled.a`
  ${tw`relative no-underline my-4 md:m-4 rounded-lg text-white overflow-hidden`};
  border-radius: 12%/6%;
  position: relative;
  &:hover ${TextWrapper} {
    opacity: 1;
  }

  &:hover ${StyledStatusLabel} {
    opacity: 1;
    overflow: hidden;
  }

  &:hover ${Overlay} {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.94);
  }
`

const StyledMobile = styled(Mobile)`
  height: 100%;
  width: 100%;
`

const Text = styled.div`
  ${tw`opacity-75 font-sans text-sm md:text-base`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`

const Title = styled.div`
  ${tw`text-white uppercase text-2xl md:text-3xl xl:text-4xl tracking-wide font-sans pt-8`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`

const ProjectMobile = ({ title, link, children, bg, status }) => (
  <Wrapper href={link} target='_blank' rel='noopener noreferrer'>
    <StyledMobile image={bg} />
    <Overlay>
      <StatusLabel status={status} />
      <TextWrapper>
        <Title>{title}</Title>
        <Text>{children}</Text>
      </TextWrapper>
    </Overlay>
  </Wrapper>
)

export default ProjectMobile

ProjectMobile.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bg: PropTypes.string.isRequired,
}
