const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Ethan Moistner - Full Stack Developer', // Navigation and Site Title
  siteTitleAlt: 'Ethan', // Alternative Site title for SEO
  siteTitleShort: 'Ethan Porfolio', // short_name for manifest
  siteHeadline: 'Ethan Moistner Full Stack Developer', // Headline for schema.org JSONLD
  siteUrl: 'https://ethanmoistner.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/d20.svg', // Used for SEO and manifest
  siteDescription:
    'Developer by trade and product enthusiast in heart. Find out what Ethan is working on or how to get in touch!',
  author: 'ethanmoistner', // Author for schema.org JSONLD

  userTwitter: '@ethanmoistner', // Twitter Username
  ogSiteName: 'ethanmoistner', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-142160077-1',

  // Manifest and Progress color
  themeColor: '#161719',
  backgroundColor: tailwind.colors.blue,
}
