import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/layout'
import ProjectWebsite from '../components/project-website'
import ProjectMobile from '../components/project-mobile'

// Elements
import Inner from '../elements/inner'
import { Title, BigTitle, Subtitle } from '../elements/titles'
import ClipPaths from '../elements/clip-paths'

// Views
import Hero from '../views/hero'
import Projects from '../views/projects'
import About from '../views/about'
import Contact from '../views/contact'

import avatar3 from '../images/avatar3.jpg'
import avatar2 from '../images/avatar2.jpg'

import indyvotetimes from '../images/indyvotetimes-2.png'
import hellscreamraiders from '../images/hellscreamraiders-2.png'
import adventure from '../images/adventure-2.png'
import vanillastatus from '../images/vanilla-status.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex items-center content-center flex-col justify-between mt-8`};
`

const MobileProjectsWrapper = styled.div`
  ${tw`flex justify-around flex-wrap w-full`};
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white font-sans pt-8 lg:pt-0 lg:ml-12 lg:pl-6 border-0 lg:border-color-white lg:border-l-4 lg:border-solid text-xl lg:text-2xl xl:text-3xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <Avatar src={avatar3} alt='Ethan Moistner' />
        <BigTitle>Ethan Moistner</BigTitle>
        <Subtitle>Full Stack Developer</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Side Projects</Title>
        <ProjectsWrapper>
          <MobileProjectsWrapper>
            <ProjectMobile
              title='Indy Vote Times'
              link='https://indyvotetimes.org'
              bg={indyvotetimes}
              status="inactive"
            >
              Realtime Polling Location Queue Monitoring Mobile Website
            </ProjectMobile>
            <ProjectMobile
              title='Vanilla Status'
              link='https://github.com/vanillastatus/VanillaStatusApp'
              bg={vanillastatus}
              status="inactive"
            >
              World of Warcraft Realm Monitoring Mobile App
            </ProjectMobile>
          </MobileProjectsWrapper>
          <ProjectWebsite title='Hellscream Raiders' link='https://hellscreamraiders.com' bg={`url(${hellscreamraiders})`} moreInfoLink='' status="active">
            World of Warcraft Guild Recruitment Website
          </ProjectWebsite>
          <ProjectWebsite title='Adventure' link='https://adventure-guild.gg' bg={`url(${adventure})`} status="inactive">
            World of Warcraft Guild Recruitment Website
          </ProjectWebsite>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <div>
            <Avatar style={{ width: '8rem' }} src={avatar2} alt='Ethan Moistner (slightly)' />
          </div>
          <AboutSub>
            If the first time developers see an idea is at sprint planning, we have failed. We need to ensure the
            feasibility before we decide to build, not after. This ends up saving a lot of wasted time and is critical
            for shared learning.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          I'm a developer by trade and product enthusiast at heart. I have been shipping production ready software since
          2014 using a variety of Agile methodologies, with the latest being Dual Track Agile. It is my belief that
          behind every great product team, there are multi-faceted people utilizing their natural and learned talents to
          solve real user problems.
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href='mailto:emmoistner@gmail.com'>Hey</a> or find me on platforms like {' '}
            <a href='https://www.linkedin.com/in/ethanmoistner'>Linkedin</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2023 Powered by Gatsby. <a href='https://github.com/emmoistner'>Github Repository</a>. Made by{' '}
          <a href='https://www.ethanmoistner.com'>Ethan Moistner</a>.
        </Footer>
      </Contact>
    </Parallax>
    <ClipPaths />
  </>
)

export default Index
