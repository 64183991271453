import React, { PureComponent } from 'react'

// This component should only be rendered once per page
export default class ClipPaths extends PureComponent {
  render() {
    return (
      <svg preserveAspectRatio='none'>
        <defs>
          <clipPath id='rightClipPath'>
            <polygon points='0 16, 100 4, 100 82, 0 94' />
          </clipPath>
          <clipPath id='leftClipPath'>
            <polygon points='0 15, 100 25, 100 85, 0 75' />
          </clipPath>
        </defs>
      </svg>
    )
  }
}
ClipPaths.propTypes = {}
