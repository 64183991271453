import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from '../elements/dividers'
import Content from '../elements/content'
import Inner from '../elements/inner'

const Projects = ({ children, offset }) => (
  <>
    <Divider
      fill='rgba(135, 195, 240, 0.45)'
      clipPath='url(#leftClipPath)'
      speed={-0.2}
      offset={`${offset}.1`}
      factor={2}
    />
    <Content speed={0.4} offset={`${offset}.2`} factor={2}>
      <Inner>{children}</Inner>
    </Content>
  </>
)

export default Projects

Projects.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
}
